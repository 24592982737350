import React, { useState } from 'react';
import ArticleMarkdownContent from './ArticleMarkdownContent';
import { LuFacebook, LuTwitter, LuInstagram, LuLinkedin } from "react-icons/lu";

import { Skeleton, User } from "@nextui-org/react";
// import { User } from "@nextui-org/react";
const ArticleAuthor = () => {
    const [isLoaded, setIsLoaded] = useState(false)

    const onLoad = () => {
        setIsLoaded(true)
    };

    return (
        <div className="text-center mt-8">
            {/* <img src="/images/justek.jpg" className="h-20 w-20 mx-auto rounded-full shadow mb-4" alt="" /> */}
            <User
                name="Justyna Panek"
                description="Quality Assurance & Content Writer"
                avatarProps={{
                    src: "/images/justek.jpg",
                    size: "lg",
                    isBordered: true,
                    color: "secondary"
                }}
            />
            {/* <a href="" className="text-lg font-medium hover:text-indigo-500 transition-all duration-500 ease-in-out h5">Justyna Panek</a>
                            <p className="text-slate-400">QA & CW</p> */}
        </div>
    )
}

export default ArticleAuthor;
