import React from 'react';
import { motion, useInView } from 'framer-motion';
import BackgroundGradient from './BackgroundGradient';

export default function Benefit({ children }) {
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <motion.div
            ref={ref}
            className="h-full"
            initial={{ opacity: 0, y: 50 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
        >
            <BackgroundGradient className="rounded-md max-w p-10 
                bg-gradient-to-b from-neutral-800 via-neutral-850 to-neutral-900 
                h-full flex flex-col">
                <div className="flex-grow">
                    {children}
                </div>
            </BackgroundGradient>
        </motion.div>
    );
}