import React, { useState } from 'react';

// import ChatComponent from '../chat/ChatComponent';
import PopularResidents from './residents/PopularResidents';
import RequestResident from './residents/RequestResident';
import UtlixContainer from '../../utils/UtlixContainer';
import { SparklesCore } from "../../utils/SparklesCore";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/motion-framer";

const LandingPage = ({ }) => {

    return (
        <UtlixContainer>
            <motion.div
                variants={fadeIn('up', 1)}
                initial="hidden"
                animate="show"
                whileInView="show"
                viewport={{ once: false, amount: 0.7 }}
                className="my-6 lg:my-10 w-full flex flex-col items-center justify-center overflow-hidden rounded-md"
            >
                <h1 className="mt-8 text-3xl font-bold tracking-tighter sm:text-xl md:text-5xl lg:text-4xl/none">
                    Zanurz się w atmosferze Twojego wymarzonego  {' '} 
                    <span className="bg-gradient-to-r from-indigo-500 to-indigo-900 text-transparent bg-clip-text">
                        hotelu {' '} 
                    </span>już teraz.
                </h1>
                <p className="py-4 lg:py-6 px-1 mx-auto text-slate-400 md:text-xl">
                    Porozmawiaj z wirtualnymi rezydentami i odkryj wszystko to co warto wiedzieć o Twoim pobycie.
                </p>

                <div className="w-[40rem] h-28 md:h-40 relative">
                    {/* Gradients */}
                    <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
                    <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />
                    <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-[5px] w-1/4 blur-sm" />
                    <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px w-1/4" />

                    {/* Core component */}
                    <SparklesCore
                        background="transparent"
                        minSize={0.4}
                        maxSize={1}
                        particleDensity={100}
                        className="w-full h-full"
                        particleColor="#FFFFFF"
                    />

                    {/* Radial Gradient to prevent sharp edges */}
                    <div className="absolute inset-0 w-full h-full bg-[#27272a] [mask-image:radial-gradient(350px_140px_at_top,transparent_20%,white)]"></div>
                    {/* <h6 className="z-50 bottom-5 text-xs md:text-sm text-slate-400">
                        Odkrywaj, Planuj, Personalizuj – Twoje wakacje zaczynają się tutaj
                    </h6> */}
                </div>

            </motion.div>
            <PopularResidents />
            <RequestResident />
        </UtlixContainer>
    );
};

export default LandingPage;