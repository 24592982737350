import React from 'react';
import { Avatar } from '@nextui-org/react';
import { motion } from "framer-motion";

const UserMessage = ({ message }) => {
    return (
        <motion.div initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="flex items-end justify-end space-x-4">
            <div className="chat-bubble max-w-xl bg-[#3f3f46] text-neutral-300 p-3 rounded-lg">
                {message.content}
            </div>
            <div className="chat-image avatar">
                <div className="w-10 h-10 rounded-full">
                    <Avatar
                        squared
                        text="Joe" />
                </div>
            </div>
        </motion.div>
    );
};

export default UserMessage;

