import React from 'react';
import PropertySocialLinks from '../shared/PropertySocialLinks.jsx';


const ChatResidentHeader = ({ resident, property }) => {
    return (
        <>  
            <div className="shadow-md images group">
                <img className="group-hover:border-indigo-800 group-hover:shadow-xl 
                            group-hover:hidden  
                            w-28 h-28 rounded-full border-4 border-neutral-900"
                src={`../images/${resident.image_filename}`}
                alt={resident.name} />

                <img className="hidden group-hover:block group-hover:border-indigo-800 
                            group-hover:shadow-xl group-hover:shadow-indigo-700/50
                            w-28 h-28 rounded-full border-4 border-neutral-900"
                src={`../images/${resident.image_filename2}`}
                alt={resident.name} />

            </div>
            <div className="ml-4"> {/* Add margin to the left of the text container */}
                <div className="font-bold text-xl text-center">
                    {`${resident.property_name}`}
                </div>
                <p className="text-gray-500 text-base mb-2 text-center">
                    {resident.name}
                </p>
                <PropertySocialLinks property={property} />
            </div>
        </>
    );
};

export default ChatResidentHeader;

