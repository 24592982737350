import React, { useState } from 'react';
import ArticleMarkdownContent from './ArticleMarkdownContent';
import ArticleAuthor from './ArticleAuthor';
import { LuFacebook, LuTwitter, LuInstagram, LuLinkedin } from "react-icons/lu";

import { Skeleton, User } from "@nextui-org/react";
// import { User } from "@nextui-org/react";
const ArticleContent = ({article}) => {
    const [isLoaded, setIsLoaded] = useState(false)

    const onLoad = () => {
        setIsLoaded(true)
    };

    return (
        <div className="container">
            <div className="grid lg:grid-cols-12 grid-cols-12 gap-6">
                <div className="col-span-12 lg:col-span-8">
                    <div className="relative overflow-hidden rounded-md dark:shadow-gray-800">
                        <Skeleton isLoaded={isLoaded} className="rounded-lg">
                            <img src={`${article.head_image}`} onLoad={onLoad} alt={`${article.title}`} />
                        </Skeleton>
                        <ArticleMarkdownContent url={article.urlslug} />
                    </div>
                </div>

                <div className="col-span-12 lg:col-span-4">
                    <div className="sticky top-20">
                        <h5 className="text-lg font-medium bg-indigo-50 dark:bg-indigo-900 shadow dark:shadow-indigo-900 rounded-md p-2 text-center">Autor</h5>
                        <ArticleAuthor />

                        <h5 className="text-lg font-medium bg-gray-50 dark:bg-indigo-900 shadow dark:shadow-indigo-900  rounded-md p-2 text-center mt-8">Social media</h5>
                        <ul className="list-none text-center mt-8 space-x-0.5">
                            <li className="inline">
                                <a href="https://www.facebook.com/profile.php?id=61560880210677" 
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-500 hover:text-white hover:bg-indigo-500">
                                    <LuFacebook className="size-4" />
                                </a>
                            </li>
                            {/* <li className="inline">
                                <a href="" className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-500 hover:text-white hover:bg-indigo-500">
                                    <LuInstagram className="size-4" />
                                </a>
                            </li> */}
                            <li className="inline">
                                <a href="https://www.linkedin.com/company/aifuturehotels" 
                                    className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-ray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-500 hover:text-white hover:bg-indigo-500"
                                    target="_blank" rel="noopener noreferrer">
                                    <LuLinkedin className="size-4" />
                                </a>
                            </li>
                            {/* <li className="inline">
                                <a href="" className="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-500 hover:text-white hover:bg-indigo-500">
                                    <LuTwitter className="size-4" />
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArticleContent;
