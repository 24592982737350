import React from 'react'
import { FaRegCheckCircle } from "react-icons/fa";
import { motion, useInView } from 'framer-motion';

export default function OurClient() {
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
      <motion.section
        ref={ref}
        className="w-full py-12 md:py-24 lg:py-24 mb-4 md:mb-8 lg:mb-16 rounded-md bg-gradient-to-b from-neutral-800 via-neutral-850 to-neutral-900"
        initial={{ opacity: 0, y: 50 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        > 
          <div className="px-4 md:px-6">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-2xl text-center mb-8">
                  Dla kogo jest nasza platforma?
              </h2>
              <div className="max-w-3xl mx-auto">
                  <p className="text-xl text-center text-gray-400 max-w-6xl mx-auto mb-12">
                      Nasza aplikacja jest stworzona dla właścicieli obiektów, którzy:
                  </p>
                  <ul className="space-y-4 mb-8 mx-auto max-w-lg text-left">
                      <li className="flex items-center mx-auto max-w-md"> {/* Center block with max width */}
                          <FaRegCheckCircle className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                          <span>Stawiają na innowacje i nie boją się wdrażać nowych technologii.</span>
                      </li>
                      <li className="flex items-center mx-auto max-w-md">
                          <FaRegCheckCircle className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                          <span>Poszukują personalizacji, która podnosi jakość obsługi i zadowolenie gości.</span>
                      </li>
                      <li className="flex items-center mx-auto max-w-md">
                          <FaRegCheckCircle className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                          <span>Pragną wyróżnić się na tle konkurencji i stać się liderami w branży.</span>
                      </li>
                      <li className="flex items-center mx-auto max-w-md"> {/* Set the same width for consistency */}
                          <FaRegCheckCircle className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                          <span>Chcą zwiększyć rezerwacje bezpośrednie i zmaksymalizować przychody, eliminując pośredników.</span>
                      </li>
                  </ul>
                  <p className="text-xl text-center text-gray-400 mx-auto mb-12">
                      Jeśli odnajdujesz się w tej grupie, to nasza platforma jest dla Ciebie.
                  </p>
              </div>
          </div>
      </motion.section>
  )
}
