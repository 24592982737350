import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import Resident from './Resident';

const PopularResidents = () => {
    const [residents, setResidents] = useState([]);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        fetch('/api/residents')
            .then(response => response.json())
            .then(data => setResidents(data));
    }, []);

    useEffect(() => {
        if (residents.length > 0) {
            const imageElements = document.querySelectorAll('img');
            const totalImages = imageElements.length;
            let loadedImages = 0;

            imageElements.forEach((img) => {
                if (img.complete) {
                    loadedImages += 1;
                } else {
                    img.onload = () => {
                        loadedImages += 1;
                        if (loadedImages === totalImages) {
                            setImagesLoaded(true);
                        }
                    };
                }
            });

            if (loadedImages === totalImages) {
                setImagesLoaded(true);
            }
        }
    }, [residents]);

    return (
        <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={imagesLoaded ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x: 0, y: 0 }}
            transition={{ duration: 1.2, delay: 1.5, type: 'tween', ease: 'easeInOut' }}
        >
            <div className="px-4 mx-auto max-w-screen-xl text-center">
                <h4 className="mb-8 text-2xl">Nowi rezydenci w naszym zespole</h4>
                <div className="grid gap-2 lg:gap-4 grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                    {
                        residents.map((resident) => (
                            <div key={resident.id} className="w-full h-full overflow-hidden">
                                <Resident resident={resident} />
                            </div>
                        ))
                    }
                </div>
                <div className="mx-auto mt-8 max-w-screen-sm lg:mt-16">
                    {/* <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Poznaj Naszych Wirtualnych Rezydentów</h2> */}
                    {/* <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
          Nasi wirtualni rezydenci to Twoje źródło wiedzy o hotelach, apartamentach i kurortach. Odkryj wszystko, co warto wiedzieć, i ciesz się wyjątkowym pobytem.
      </p> */}
                </div>
            </div>
        </motion.section>
    );
};

export default PopularResidents;