import React, { useEffect, useState } from 'react';
import { Skeleton } from "@nextui-org/skeleton";
import { motion } from "framer-motion";
import PropertySocialLinks from '../../shared/PropertySocialLinks.jsx';

const Resident = ({ resident }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const onLoad = () => {
        setIsLoaded(true);
    };

    return (
        <motion.div
            className="relative rounded shadow-lg pb-4"
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
        >
            <a href={`/residents/${resident.slug}`} className="group">
                <Skeleton isLoaded={isLoaded} className="rounded-lg">
                    <img className="max-h-80 group-hover:blur-sm rounded-md group-hover:rounded-lg"
                        src={`images/${resident.background_image}`}
                        alt="Sunset in the mountains"
                        onLoad={onLoad} />
                </Skeleton>

                <img
                    className="size-28 md:size-40 lg:size-24 xl:size-28 
                        group-hover:border-indigo-800 group-hover:shadow-xl 
                        group-hover:hidden absolute m-auto left-0 right-0 top-7 
                        overflow-visible mb-4 rounded-full border-3 border-neutral-900"
                    src={`/images/${resident.image_filename}`}
                    alt={`${resident.name}`}
                />

                <motion.img
                    className="hidden group-hover:block size-28 md:size-40 lg:size-24 xl:size-28 
                        group-hover:border-indigo-800 group-hover:shadow-xl group-hover:shadow-indigo-700/50 
                        absolute m-auto left-0 right-0 top-7 
                        overflow-visible mb-4 rounded-full border-3 border-neutral-900"
                    src={`images/${resident.image_filename2}`}
                    alt={`${resident.name}`}
                    animate={isHovered ? { scale: 1.1, rotate: 5 } : { scale: 1, rotate: 0 }}
                    transition={{ type: "spring", stiffness: 300 }}
                />

                <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2"> {`${resident.property_name}`}</div>
                    <p className="text-gray-500 text-base">
                        {resident.name}
                    </p>
                </div>
            </a>
            <PropertySocialLinks property={resident.property} />
        </motion.div>
    );
};

export default Resident;