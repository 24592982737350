import React from 'react'

import { CiClock2 } from "react-icons/ci";
import { FaGlobeEurope, FaRegStar } from "react-icons/fa";
import { GrLineChart } from "react-icons/gr";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { PiUsersThreeDuotone } from "react-icons/pi";
import { MdOutlineDashboardCustomize, MdOutlineSecurity, MdOutlineQueryStats, MdAutoStories } from "react-icons/md";
import { motion } from "framer-motion";
import { fadeIn } from "../../../utils/motion-framer";
import Benefit from './Benefit.jsx';


export default function BenefitsList() {
  return (
      <motion.section
          variants={fadeIn('up', 1)}
          initial="hidden"
          animate="show"
          whileInView="show"
          viewport={{ once: false, amount: 0.7 }}
            id="benefits" className="w-full pb-12 md:pb-24 lg:pb-24">
          <div className="container">
              <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-center mb-8">Dlaczego współpraca z nami to krok w przyszłość?</h2>
              <p className="text-xl text-center text-gray-400 max-w-6xl mx-auto mb-12">
                  Jeśli prowadzisz hotel lub resort, wiesz, że każdy gość szuka wyjątkowego doświadczenia. Jednak w czasach, gdy podróżni oczekują natychmiastowej obsługi i pełnej personalizacji, tradycyjne metody zarządzania gośćmi mogą nie wystarczyć. Nasza platforma oparta na sztucznej inteligencji to narzędzie stworzone specjalnie dla liderów i innowatorów, którzy wyznaczają nowe standardy w branży hotelarskiej.
              </p>
              <div className="grid gap-10 lg:grid-cols-2">
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          <CiClock2 className="h-20 w-20 text-blue-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Całodobowa obsługa – zawsze w kontakcie z Twoimi gośćmi
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          Twoi goście potrzebują odpowiedzi na pytania o każdej porze dnia i nocy. Nasz wirtualny rezydent, działający 24/7, odpowiada na ich potrzeby w czasie rzeczywistym. Od zapytań dotyczących rezerwacji po udzielanie informacji o usługach – zapewnia pełną obsługę bez przerw.
                      </p>
                  </Benefit>
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          <FaRegStar className="h-20 w-20 mb-4 text-yellow-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Spersonalizowana obsługa, która zwiększa satysfakcję i przychody
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          Nasi goście oczekują indywidualnego podejścia. Nasza aplikacja analizuje preferencje gości i oferuje im spersonalizowane rekomendacje – od podwyższenia standardu pokoju po usługi dodatkowe. Dzięki temu zwiększysz zadowolenie gości i wykorzystasz każdą okazję na dodatkowy dochód.
                      </p>
                  </Benefit>
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          <IoShieldCheckmarkOutline className="h-20 w-20 mb-4 text-red-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Inteligentne dane – podejmuj decyzje na podstawie realnych informacji
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          Nie musisz zgadywać, co działa, a co nie. Nasza platforma dostarcza danych o preferencjach i zachowaniach Twoich gości. Śledź, jak często korzystają z usług, jakie pytania zadają i które opcje wybierają najczęściej. Z takimi informacjami łatwo zoptymalizujesz swoje działania, maksymalizując zyski.
                      </p>
                  </Benefit>
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          <PiUsersThreeDuotone className="h-20 w-20 mb-4 text-purple-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Zawsze o krok przed konkurencją
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          Hotelarstwo zmienia się szybko, ale z naszą technologią możesz być pewien, że Twoja oferta będzie zawsze nowoczesna i dostosowana do oczekiwań współczesnych podróżników. Wprowadzenie innowacji AI to nie tylko udogodnienie – to sygnał dla rynku, że Twój hotel jest liderem.
                      </p>
                  </Benefit>
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          <FaGlobeEurope className="h-20 w-20 mb-4 text-indigo-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Obsługa międzynarodowa – bez barier językowych
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          Zarządzanie hotelem o międzynarodowej klienteli to wyzwanie? Nie z nami. Nasz wirtualny rezydent obsługuje wiele języków, co oznacza, że goście z całego świata mogą bezproblemowo komunikować się z Twoim hotelem. To dodatkowa korzyść, która podnosi poziom zadowolenia.
                      </p>
                  </Benefit>
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          <MdOutlineDashboardCustomize className="h-20 w-20 mb-4 text-orange-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Pełna personalizacja pod Twoją markę
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          Każdy hotel ma swój charakter, a nasza aplikacja pozwala na pełną personalizację – od nazw, przez komunikaty, po wizualny styl. Dzięki temu Twój wirtualny rezydent idealnie dopasuje się do estetyki i wartości Twojego hotelu, wzmacniając Twoją markę.
                      </p>
                  </Benefit>
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          <GrLineChart className="h-20 w-20 text-green-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Więcej rezerwacji bezpośrednich, mniej prowizji
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          Dlaczego oddawać zyski platformom rezerwacyjnym, skoro możesz je zatrzymać dla siebie? Nasze narzędzie umożliwia bezpośrednie rezerwacje poprzez wirtualnego rezydenta, co nie tylko zmniejsza koszty, ale również ułatwia klientom wybór Twojej oferty.
                      </p>
                  </Benefit>
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          <MdOutlineSecurity className="h-20 w-20 mb-4 text-violet-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Proste wdrożenie, pełne bezpieczeństwo
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          Jako właściciel hotelu cenisz swój czas. Nasze narzędzie jest proste we wdrożeniu i skalowalne, co oznacza, że łatwo dostosowuje się do potrzeb małych, średnich i dużych obiektów. Wszystkie procesy są zgodne z RODO i zapewniają pełne bezpieczeństwo Twoich danych oraz prywatności gości.
                      </p>
                  </Benefit>
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          <MdOutlineQueryStats className="h-20 w-20 text-purple-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Wirtualny manager reputacji: całodobowy monitoring opinii
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          Nasz Wirtualny Manager monitoruje opinie o Twoim hotelu 24/7, analizując je w czasie rzeczywistym. Dzięki analizie sentymentu możesz szybko reagować na negatywne recenzje i wzmacniać pozytywne, budując silniejszą reputację swojego obiektu.
                      </p>
                  </Benefit>
                  <Benefit>
                      <div className="flex justify-center mb-4">
                          <MdAutoStories className="h-20 w-20 mb-4 text-yellow-500" />
                      </div>
                      <p className="text-base sm:text-xl text-black mt-4 mb-2 dark:text-neutral-200">
                          Inteligentna analiza zapytań gości: wyprzedzaj oczekiwania
                      </p>
                      <p className="text-sm text-neutral-600 dark:text-neutral-400">
                          System analizuje zapytania gości, identyfikując trendy i potrzeby. Regularne raporty umożliwiają dostosowanie usług, co pozwala przewidywać oczekiwania gości i spełniać je zanim jeszcze je wyrażą.
                      </p>
                  </Benefit>
              </div>
          </div>
      </motion.section>
    )
}


