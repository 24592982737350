import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import LoadingDots from '../shared/LoadingDots';

const AIMessage = ({ message, resident, isLoading }) => {
    return (
        <motion.div initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="flex items-start space-x-4">
            <div className="chat-image avatar">
                <div className="w-12 h-12 rounded-full overflow-hidden">
                    <img
                        alt="AI Bot avatar"
                        src={`../images/${resident.image_filename}`}
                        className="object-cover"
                    />
                </div>
            </div>
            <div className="chat-bubble max-w-xl bg-gradient-to-r from-neutral-700 to-neutral-800 
                text-neutral-300 p-3 border-2 border-indigo-600 rounded-tl-[6px] rounded-bl-[16px] rounded-br-[16px] rounded-tr-[16px]">
                {isLoading ? <LoadingDots /> : message.content}
            </div>
        </motion.div>
    );
};

export default AIMessage;