import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Messages from './Messages.jsx';
import ChatResidentHeader from './ChatResidentHeader.jsx';
import RateLimitModal from '../shared/RateLimitModal';
import { Button, Textarea } from "@nextui-org/react";
import { LuSendHorizonal } from "react-icons/lu";

const ChatWithResident = ({ resident, property }) => {
    const [currentDiscussion, setCurrentDiscussion] = useState(null);
    const [inputMessage, setInputMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isRateLimitModalOpen, setIsRateLimitModalOpen] = useState(false);

    const chatPanelRef = useRef(null);

    // useEffect to scroll to the bottom after new message is added
    useEffect(() => {
        if (chatPanelRef.current) {
            const chatPanelElement = chatPanelRef.current;
            chatPanelElement.scrollTo(0, chatPanelElement.scrollHeight);
        }
    }, [messages]);

    useEffect(() => {
        const savedDiscussionId = localStorage.getItem(`${resident.property_name}-discussion`);
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf
        axios.get(`/residents/${resident.slug}/discussions`, {
            params: { discussion_id: savedDiscussionId }
        })
            .then(response => {
                const discussion = response.data.data.discussion;
                setCurrentDiscussion(discussion);
                setMessages(response.data.data.messages)
                localStorage.setItem(`${resident.property_name}-discussion`, discussion.id);
            })
            .catch(error => {
                console.error('Error fetching discussion:', error);
            });

    }, [resident.id, resident.property_name]);

    const handleSubmit = async () => {
        if (inputMessage.trim() !== '') {
            // Add the user's message to the chat
            setMessages((prevMessages) => [
                ...prevMessages,
                { sender: 'User', content: inputMessage }
            ]);

            // Add a temporary AI message with loading dots
            setMessages((prevMessages) => [
                ...prevMessages,
                { sender: 'AI Bot', content: '', isLoading: true }
            ]);

            setInputMessage('');
            setSendButtonDisabled(true);
            setIsLoading(true);

            const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")
            axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf
            let url = `/residents/${resident.slug}/discussions/${currentDiscussion.id}/messages`;

            try {
                const response = await axios.post(url, {
                    message: {
                        content: inputMessage
                    }
                });

                if (response.data.error) {
                    console.log('Error saving new message');
                } else {
                    console.log('Message sent successfully!');
                    console.log('response message:', response.data);

                    // Replace the temporary AI message with the actual response
                    setMessages((prevMessages) => {
                        const newMessages = [...prevMessages];
                        const newLastTwoMessages = response.data.data.messages;
                        newMessages.pop(); // Remove the last temporary AI message
                        newMessages.push({ sender: 'AI Bot', content: newLastTwoMessages[newLastTwoMessages.length - 1].content });
                        return newMessages;
                    });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                if (error.response && error.response.status === 429) {
                    setIsRateLimitModalOpen(true);
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleChange = (e) => {
        const newValue = e.target.value;
        setSendButtonDisabled(newValue.length === 0);
        setInputMessage(newValue);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSubmit();
        }
    };

    const handleRateLimitModalClose = () => {
        setIsRateLimitModalOpen(false);
        setSendButtonDisabled(true);

        setMessages(prevMessages => {
            // Find the index of the last AI Bot message with isLoading: true
            const lastLoadingIndex = prevMessages.findIndex(
                message => message.sender === 'AI Bot' && message.isLoading
            );

            // If there's a loading message, replace it with the new message
            if (lastLoadingIndex !== -1) {
                const newMessages = [...prevMessages];
                newMessages[lastLoadingIndex] = {
                    sender: 'AI Bot',
                    content: 'Proszę utwórz konto, aby odblokować nieograniczone rozmowy.'
                };
                return newMessages;
            }

            // If no loading message is found, just add the new message
            return [
                ...prevMessages,
                {
                    sender: 'AI Bot',
                    content: 'Proszę utwórz konto, aby odblokować nieograniczone rozmowy.'
                }
            ];
        });
    };

    return (
        <div className="flex flex-col justify-between" style={{ height: 'calc(100vh - 56px)' }}>
            <div className='hidden md:flex basis-3/12 items-center justify-center py-2.5'>
                {/* <ChatbotHeader resident={resident} property={property} /> */}
                <ChatResidentHeader resident={resident} property={property} />
            </div>
            <div ref={chatPanelRef} className="flex-grow overflow-y-auto">
                <Messages messages={messages} resident={resident} />
            </div>
            <div id='chat-input' className="flex-none align-middle py-2.5">
                <div className="flex flex-row gap-x-2 justify-items-center overflow-auto">
                    <Textarea
                        className='text-neutral-400'
                        minRows={1}
                        radius='sm'
                        color="primary"
                        variant='bordered'
                        placeholder={`Napisz wiadomość do ${resident.name}`}
                        value={inputMessage}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                    <Button isIconOnly
                        radius='sm'
                        color="primary"
                        isDisabled={sendButtonDisabled}
                        isLoading={isLoading}
                        onClick={handleSubmit}>
                        <LuSendHorizonal />
                    </Button>
                </div>
            </div>
            <RateLimitModal
                isOpen={isRateLimitModalOpen}
                onClose={handleRateLimitModalClose}
            />
        </div>
    );
};

export default ChatWithResident;