export const fadeIn = (direction = 'up', delay = 0) => {
    const variants = {
        hidden: {
            opacity: 0,
            y: direction === 'up' ? 20 : direction === 'down' ? -20 : 0,
            x: direction === 'left' ? 20 : direction === 'right' ? -20 : 0,
        },
        show: {
            opacity: 1,
            y: 0,
            x: 0,
            transition: {
                type: 'tween',
                duration: 1.2,
                ease: 'easeInOut',
                delay: delay,
            },
        },
    };
    return variants;
};