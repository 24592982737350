import React from 'react';

import UtlixContainer from '../../utils/UtlixContainer.jsx';
import CooperativeHeadSection from './cooperative/CooperativeHeadSection.jsx';
import BenefitsList from './cooperative/BenefitsList.jsx';
import OurClient from './cooperative/OurClient.jsx';
import WaitingListForm from './cooperative/WaitingListForm.jsx';

export default function Cooperative() {
    return (
        <UtlixContainer>
            <div className="flex flex-col min-h-screen">
                <main className="flex-1">
                    <CooperativeHeadSection />
                    <BenefitsList />
                    <OurClient />
                    <WaitingListForm />
                </main>
                {/* <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
                    <p className="text-xs text-gray-500">© 2024 Virtual Resident. Wszelkie prawa zastrzeżone.</p>
                    <nav className="sm:ml-auto flex gap-4 sm:gap-6">
                        <Link className="text-xs hover:underline underline-offset-4" href="#">
                            Polityka prywatności
                        </Link>
                        <Link className="text-xs hover:underline underline-offset-4" href="#">
                            Warunki korzystania
                        </Link>
                    </nav>
                </footer> */}
            </div>
        </UtlixContainer>

    )
}