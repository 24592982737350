import React from 'react';
import { LuHotel } from "react-icons/lu";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { motion } from "framer-motion";
const PropertySocialLinks = ({ property }) => {
    return (
        <ul className="flex justify-center mb-4 space-x-4">
            {property.hotel_url && (
                <motion.li
                    whileHover={{ scale: 1.1 }}
                >
                    <a href={property.hotel_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#d3b11c] hover:text-gray-900 dark:hover:text-white">
                        <LuHotel className="w-6 h-6" />
                    </a>
                </motion.li>
            )}
            {property.facebook_url && (
                <motion.li
                    whileHover={{ scale: 1.1 }}
                >
                    <a href={property.facebook_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#39569c] hover:text-gray-900 dark:hover:text-white">
                        <FaFacebook className="w-6 h-6" />
                    </a>
                </motion.li>
            )}
            {property.twitter_url && (
                <motion.li
                    whileHover={{ scale: 1.1 }}
                >
                    <a href={property.twitter_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#00acee] hover:text-gray-900 dark:hover:text-white">
                        <FaTwitter className="w-6 h-6" />
                    </a>
                </motion.li>
            )}
            {property.instagram_url && (
                <motion.li>
                    <a href={property.instagram_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#ea4c89] hover:text-gray-900 dark:hover:text-white">
                        <FaInstagram className="w-6 h-6" />
                    </a>
                </motion.li>
            )}
        </ul>
    );
};

export default PropertySocialLinks;