import { cn } from "../../../utils/cn";
import React, { useState } from "react";
import { motion } from "framer-motion";

export default function BackgroundGradient({
    children,
    className,
    containerClassName,
}) {
    const [isHovered, setIsHovered] = useState(false);

    const variants = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            backgroundPosition: ["0% 50%", "100% 50%", "0% 50%"],
        },
    };

    return (
        <div
            className={cn(
                "relative p-[1px] group h-full rounded-md",
                "border-1 border-neutral-800", // Red border by default
                containerClassName
            )}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <motion.div
                variants={variants}
                initial="initial"
                animate={isHovered ? "animate" : "initial"}
                transition={{
                    duration: 5,
                    repeat: isHovered ? Infinity : 0,
                    repeatType: "reverse",
                }}
                style={{
                    backgroundSize: "400% 400%",
                }}
                className={cn(
                    "absolute inset-[-2px] rounded-md z-[1] blur-lg transition duration-500",
                    "bg-gradient-to-r from-transparent via-indigo-500 to-sky-500"                )}
            />
            <motion.div
                variants={variants}
                initial="initial"
                animate={isHovered ? "animate" : "initial"}
                transition={{
                    duration: 2.5,
                    repeat: isHovered ? Infinity : 0,
                    repeatType: "reverse",
                }}
                style={{
                    backgroundSize: "400% 400%",
                }}
                className={cn(
                    "absolute inset-[-2px] rounded-md z-[1]",
                    "bg-gradient-to-r from-transparent via-indigo-500 to-sky-500"
                )}
            />
            <div className={cn("relative z-10 h-full", className)}>{children}</div>
        </div>
    );
}