import React from 'react';
import { motion } from 'framer-motion';

const LoadingDots = () => {
    const dotVariants = {
        start: { y: 0 },
        end: { y: -5 }
    };

    const dotTransition = {
        duration: 0.5,
        repeat: Infinity,
        repeatType: 'reverse',
        ease: 'easeInOut'
    };

    return (
        <div className="flex space-x-1">
            {[...Array(3)].map((_, index) => (
                <motion.div
                    key={index}
                    className="w-1 h-1 bg-indigo-600 rounded-[2px]"
                    variants={dotVariants}
                    initial="start"
                    animate="end"
                    transition={{ ...dotTransition, delay: index * 0.2 }}
                />
            ))}
        </div>
    );
};

export default LoadingDots;