import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from "@nextui-org/react";

const RateLimitModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      backdrop="blur"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">Osiągnięto limit wiadomości</ModalHeader>
            <ModalBody>
              <p>
                Osiągnąłeś maksymalną liczbę wiadomości dozwolonych dla gościa.
                Utwórz konto, aby kontynuować czat i cieszyć się nieograniczonymi rozmowami!
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" variant="light" onPress={onClose}>
                Zamknij
              </Button>
              {/* <Button color="primary" href="/signup" as="a">
                Utwórz konto
              </Button> */}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default RateLimitModal;