import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import ChatWithResident from './ChatWithResident.jsx'

const ResidentShow = ({ resident, property }) => {
    return (
        <motion.section initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="m-2">
            <div className="flex flex-row">
                <div className="hidden md:flex basis-1/12 lg:basis-3/12 text-center">
                {/* Aboout resident section */}
                </div>
                <div className="w-full sm:basis-12/12 md:basis-10/12 lg:basis-6/12 "> 
                    <ChatWithResident resident={resident} property={property} />
                </div>
                <div className="hidden md:flex basis-1/12 lg:basis-3/12 text-center">
                    {/* Aboout hotel/resort section */}
                </div>
            </div>
        </motion.section>
    );
};

export default ResidentShow;